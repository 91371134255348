.App {
    text-align: center;
}

.cav{
    position: absolute;
    width: 75vw;
    height: calc(75vw * 0.6352941176);
    max-height: calc(520px * 0.6352941176);
    max-width: 520px; 
    border-radius : 5%;
    z-index:2;
    border: 2px solid red;
    left: 50%; 
	margin-left: -260px;
}



.captured-photo{
    position: relative;
    border: 3px black solid;
    width: 75vw;
    height: calc(75vw * 0.6352941176);
    max-height: calc(520px * 0.6352941176);
    max-width: 520px; 
    border-radius : 5%;
}

.cav-face{
    position: absolute;
    width:310px;
    height:380px;
    border-radius: 50%;
    z-index: 2;
    margin-top: 33px;
    left: 50%; 
	margin-left: -155px;
    border: 2px solid red;
}

.camera{
    position: relative;
    border: 3px black solid;
    width: 75vw;
    height: calc(75vw * 0.6352941176) !important;
    max-height: calc(520px * 0.6352941176) !important;
    max-width: 520px !important; 
    object-fit: cover;
    border-radius : 5%;
}

.button{
    margin-top: 3px;
    position :inherit;
}

.title{
    font-size: 18px;
    margin-top: 10px;
    color: black;
    margin-bottom: 11px;
}

.title-focus{
    font-weight: bold;
    text-decoration: underline;
    display: inline;
}

.result-photo{
    width:200px;
    height:250px;
    margin-top: 20px
}

.link{
    color:red;
    text-decoration: underLine;
}